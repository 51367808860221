import React from 'react'

const Footer = ({ siteTitle }) => (
  <footer>
    &copy; 2012-{new Date().getFullYear()}, Built by
    {` `}
    <a
      href="https://www.twitter.com/angryjenkins"
      target="_blank"
      rel="noopener noreferrer"
      className="footer_link"
    >
      @angryjenkins
    </a>
  </footer>
)

export default Footer
